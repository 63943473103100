import {
  handleQueryResolve
} from '../utils';

export default function () {
  return this.query(`
      SELECT
          Mfr
      FROM [Fillware].[dbo].[DrugPack]
          INNER JOIN [Fillware].[dbo].[DrugRoot] ON[Fillware].[dbo].[DrugRoot].DIN = [Fillware].[dbo].[DrugPack].DINx
      WHERE Mfr <> '' OR Mfr IS NOT NULL
      GROUP BY 
          Mfr
      ORDER BY Mfr ASC
  `).then(handleQueryResolve);
  // .then((records) => {
  //   console.log(records);
  //   return records.map(row => buildMapToField(row));
  // });
}